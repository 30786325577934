<template>
  <a-upload
    name="file"
    :disabled="disabled"
    :fileList="fileList"
    :multiple="true"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <a-button :disabled="disabled">
      <a-icon type="upload" />
      {{ $t('上传附件') }}
    </a-button>
  </a-upload>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
export default {
  props: {
    list: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  watch: {
    list: {
      handler(val) {
        this.fileList = val
      },
      immediate: true,
    },
  },
  methods: {
    beforeUpload(file, fileList) {
      this.fileList = fileList
      return false // 阻止默认上传行为
    },
    async changeUpload() {
      let attachmentList = []
      for (const file of this.fileList) {
        if (file.originFileObj) {
          let res = await http({
            url: api.filePreUpload,
            data: {
              file_name: file.name,
              appCode: 'FMS',
              'Content-Type': file.type,
            },
          })
          fetch(res.result.upload_url, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': res.result.content_type,
            },
          })
          attachmentList.push({
            fileName: file.name,
            fileUrl: res.result.image_url,
          })
        } else {
          attachmentList.push({
            fileName: file.name,
            fileUrl: file.url,
          })
        }
      }
      Promise.resolve(attachmentList)
    },
    handleChange({ fileList }) {
      this.fileList = fileList
      this.hasChange = true
    },
  },
}
</script>
