<template>
  <a-date-picker
    style="width: 100%"
    v-bind="propsValue"
    :placeholder="placehold || $t('选择时间')"
    :disabled="disabled"
    :disabledDate="disabledDate"
    :value="value"
    @change="onChange"
  />
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    id: {},
    initDateTime: {},
    placehold: {},
    disabled: {},
    needhms: {
      type: Boolean,
      default: true,
    },
    aftertoday: {},
    disabledDate: {},
  },
  computed: {
    ...mapState(['lang', 'locale']),
    propsValue() {
      return {
        id: this.id || 'datepicker',
        showTime: this.needhms,
        locale: this.locale.Calendar,
        format: this.format,
      }
    },
    format() {
      // let format =
      //   this.lang === 'zh' ? 'YYYY-MM-DD HH:mm:ss' : this.locale.Calendar.lang.dateTimeFormat
      return this.needhms ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
    },
  },
  watch: {
    initDateTime(val) {
      // console.log(val)
      this.value = val
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.value = this.initDateTime != undefined ? this.initDateTime : null
    })
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    onChange(m_value, value) {
      this.value = m_value
      this.$emit('choose', {
        componentId: this.id,
        data: value,
      })
    },
  },
}
</script>
