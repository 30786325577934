<template>
  <div class="asset-card-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="PhysicalInfo" :tab="$t(`实物信息`)"></a-tab-pane>
          <a-tab-pane key="FinancialInfo" :tab="$t(`财务信息`)"></a-tab-pane>
          <a-tab-pane key="Attachment" :tab="$t(`附件`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="operateAssetCard"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            api="operateAssetCard"
            type="danger"
            :text="$t('作废')"
            :params="{
              action: 'DELETE',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="isDetail && !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.status)"
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('基本信息')"
              :showCopy="isDetail"
              @copyOrder="copyOrder"
            >
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`卡片编号`)">
                    <a-input disabled v-model="formData.cardNo"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`资产编码`)">
                    <a-input disabled v-model="formData.assetCode"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`货主组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :placeholder="$t('货主组织')"
                      :disabled="isDisabled"
                      :code.sync="formData.ownerBusinessUnitCode"
                      :name.sync="formData.ownerBusinessUnitName"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`资产类别`)" required>
                    <CommonQuerySelect
                      :placeholder="$t('资产类别')"
                      api="getAssetTypeList"
                      :disabled="isDisabled"
                      :code.sync="formData.assetTypeCode"
                      :name.sync="formData.assetTypeName"
                      valueName="asset_type_code"
                      labelName="asset_type_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`资产名称`)" required>
                    <a-input
                      v-model="formData.assetName"
                      :placeholder="$t('资产名称')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input
                      v-model="formData.remark"
                      :placeholder="$t('备注')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input :value="assetCardStatusMapping[formData.status]" :disabled="true" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="PhysicalInfo" :title="$t('实物信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`条形码`)">
                    <a-input disabled v-model="formData.physicalInfo.barcode"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`开始使用日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.physicalInfo.startUseDate"
                      @choose="chooseStartUseDate"
                      :disabled="isDisabled"
                      :placehold="$t(`开始使用日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`规格型号`)">
                    <a-input
                      v-model="formData.physicalInfo.spec"
                      :placeholder="$t('规格型号')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`资产数量`)" required>
                    <a-input-number
                      style="width: 100%"
                      v-model="formData.physicalInfo.quantity"
                      :placeholder="$t('资产数量')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`计量单位`)" required>
                    <CommonQuerySelect
                      :placeholder="$t('计量单位')"
                      api="getAssetUnitList"
                      :disabled="isDisabled"
                      :code.sync="formData.physicalInfo.unit"
                      :name.sync="formData.physicalInfo.unitName"
                      valueName="asset_unit_code"
                      labelName="asset_unit_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`增减方式`)" required>
                    <CommonQuerySelect
                      :placeholder="$t('增减方式')"
                      :params="{
                        inDecreaseType: 'INCREASE',
                      }"
                      api="getInDecreaseMethodList"
                      :disabled="isDisabled"
                      :code.sync="formData.physicalInfo.source"
                      :name.sync="formData.physicalInfo.sourceName"
                      valueName="in_decrease_code"
                      labelName="in_decrease_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`供应商`)">
                    <CommonQuerySelect
                      api="queryPurchaseSupplierList"
                      :params="{
                        status: 1,
                      }"
                      :placeholder="$t(`供应商`)"
                      :code.sync="formData.physicalInfo.supplierCode"
                      :name.sync="formData.physicalInfo.supplierName"
                      :disabled="isDisabled"
                      valueName="supplier_code"
                      labelName="supplier_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`存放地点`)" required>
                    <CommonQuerySelect
                      :placeholder="$t('存放地点')"
                      api="getStoreLocationList"
                      :disabled="isDisabled"
                      :code.sync="formData.physicalInfo.storeLocation"
                      :name.sync="formData.physicalInfo.storeLocationName"
                      valueName="location_code"
                      labelName="location_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`使用状态`)" required>
                    <CommonQuerySelect
                      :placeholder="$t('使用状态')"
                      api="getAssetUseStatusList"
                      :disabled="isDisabled"
                      :code.sync="formData.physicalInfo.useStatus"
                      :name.sync="formData.physicalInfo.useStatusName"
                      valueName="use_status_code"
                      labelName="use_status_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`使用部门`)" required>
                    <CommonQuerySelect
                      api="getDepartmentList"
                      :placeholder="$t('使用部门')"
                      :disabled="isDisabled"
                      :code.sync="formData.physicalInfo.departmentCode"
                      :name.sync="formData.physicalInfo.departmentName"
                      @change="changeBU2"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="6">
                  <div class="img-list flex-ct" v-if="formData.physicalInfo.imageUrl">
                    <div class="img-item">
                      <img
                        :src="formData.physicalInfo.imageUrl"
                        alt=""
                        style="width: 100px; height: 100px; object-fit: cover; cursor: pointer"
                        @click="handlePreview"
                      />
                      <a-icon
                        v-if="!isDisabled"
                        type="delete"
                        class="ml10"
                        style="color: red; cursor: pointer"
                        @click="deleteImg"
                      />
                    </div>
                  </div>
                  <a-upload
                    v-else
                    accept="image/*,.pdf"
                    list-type="picture-card"
                    :disabled="isDisabled"
                    :show-upload-list="false"
                    class="avatar-uploader"
                    :before-upload="beforeUpload"
                    :custom-request="changeUpload"
                  >
                    <div>
                      <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">{{ $t('上传图片') }}</div>
                    </div>
                  </a-upload>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="FinancialInfo" :title="$t('财务信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`会计政策`)" required>
                    <CommonQuerySelect
                      :init="true"
                      :placeholder="$t('会计政策')"
                      api="getAccountingPolicyPage"
                      :disabled="true"
                      :code.sync="formData.financialInfo.accountingPolicyCode"
                      :name.sync="formData.financialInfo.accountingPolicyName"
                      valueName="policy_code"
                      labelName="policy_name"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <div class="infoTitle">
                <div>
                  <span>{{ $t('原值') }}</span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`资产原值`)">
                    <PriceInput
                      :placeholder="$t(`资产原值`)"
                      :value.sync="formData.financialInfo.originalValue"
                      :disabled="isDisabled"
                      @blur="onBlur"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`进项税额`)">
                    <PriceInput
                      :placeholder="$t(`进项税额`)"
                      :value.sync="formData.financialInfo.inputTax"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`入账日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.financialInfo.receiptDate"
                      @choose="chooseReceiptDate"
                      :disabled="isDisabled"
                      :placehold="$t(`入账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`折旧方法`)">
                    <CommonQuerySelect
                      :placeholder="$t('折旧方法')"
                      api="getDepreciationMethodList"
                      :disabled="isDisabled"
                      :code.sync="formData.financialInfo.depreciationMethodCode"
                      :name.sync="formData.financialInfo.depreciationMethodName"
                      valueName="depreciation_method_code"
                      labelName="depreciation_method_name"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`预计使用期间数`)" required>
                    <a-input-number
                      v-model="formData.financialInfo.expectUsagePeriods"
                      style="width: 100%"
                      :placeholder="$t('预计使用期间数')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`预计净残值`)">
                    <PriceInput
                      :placeholder="$t(`预计净残值`)"
                      :value.sync="formData.financialInfo.netResidualValue"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <div class="infoTitle">
                <div>
                  <span>{{ $t('折旧') }}</span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item>
                    <template slot="label">
                      {{ $t('净值') }}
                      <a-popover>
                        <template slot="content">
                          <div>
                            {{ $t('净值=资产原值-累计折旧') }}
                          </div>
                        </template>
                        <a-icon type="question-circle" />
                      </a-popover>
                    </template>
                    <PriceInput
                      :placeholder="$t(`净值`)"
                      :value.sync="formData.financialInfo.netValue"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item>
                    <template slot="label">
                      {{ $t('净额') }}
                      <a-popover>
                        <template slot="content">
                          <div>
                            {{ $t('净额=资产原值-累计折旧-减值准备') }}
                          </div>
                        </template>
                        <a-icon type="question-circle" />
                      </a-popover>
                    </template>
                    <PriceInput
                      :placeholder="$t(`净额`)"
                      :value.sync="formData.financialInfo.netAmount"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item>
                    <template slot="label">
                      {{ $t('已折旧期间数') }}
                      <a-popover>
                        <template slot="content">
                          <div>
                            {{
                              $t(
                                '新增非工作量法、非初始化的资产卡片时无需填写，计提折旧时系统会自动补提开始使用日期至入账日期之间应提的折旧额'
                              )
                            }}
                          </div>
                        </template>
                        <a-icon type="question-circle" />
                      </a-popover>
                    </template>
                    <a-input-number
                      style="width: 100%"
                      :placeholder="$t(`已折旧期间数`)"
                      v-model="formData.financialInfo.depreciatedPeriods"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`累计折旧`)">
                    <PriceInput
                      :placeholder="$t(`累计折旧`)"
                      :value.sync="formData.financialInfo.accumulatedDepreciation"
                      :disabled="isDisabled"
                      @blur="onBlur"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本年累计折旧`)">
                    <PriceInput
                      :placeholder="$t(`本年累计折旧`)"
                      :value.sync="formData.financialInfo.thisYearAccumulatedDepreciation"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`减值准备`)">
                    <PriceInput
                      :placeholder="$t(`减值准备`)"
                      :value.sync="formData.financialInfo.impairmentProvision"
                      :disabled="isDisabled"
                      @blur="onBlur"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>

            <ModalBox id="Attachment" :title="$t('附件')">
              <div class="attachment-list">
                <Attachment ref="AttachmentRef" :list="fileList" :disabled="isDisabled" />
              </div>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <a-modal width="80%" :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="formData.physicalInfo.imageUrl" />
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import DatePicker from '@component/datePicker'
import Attachment from '@component/attachment'
import { convertKeysToCamelCase, goBack } from '@/common'
import { subtract } from '@/common/number'
export default {
  name: 'AssetCardDetail',
  components: { BottomBtns, OperationLog, DatePicker, Attachment },
  data() {
    return {
      docType: 'AssetCard',
      id: undefined,
      sn: undefined,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        ownerBusinessUnitCode: undefined,
        ownerBusinessUnitName: undefined,
        assetTypeCode: undefined,
        assetTypeName: undefined,
        assetName: undefined,
        physicalInfo: {
          imageUrl: undefined,
        },
        financialInfo: {
          netValue: undefined,
          netAmount: undefined,
          receiptDate: undefined,
        },
        attachmentList: [],
        status: 'DRAFT',
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      previewVisible: false,
      fileInfo: null,
      uploadLoading: false,
      fileList: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapGetters(['assetCardStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      this.isCopy = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.financialInfo.receiptDate = moment().format('YYYY-MM-DD')
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    chooseStartUseDate(data) {
      this.formData.physicalInfo.startUseDate = data.data
    },
    chooseReceiptDate(data) {
      this.formData.financialInfo.receiptDate = data.data
    },
    changeBU(value) {
      this.formData.ownerBusinessUnitCode = value.code
      this.formData.userBusinessUnitCode = value.code
      this.formData.ownerBusinessUnitName = value.name
      this.formData.userBusinessUnitName = value.name
      this.formData.financialInfo.functionalCurrency = value.currency
      this.formData.financialInfo.settlementCurrency = value.currency
    },
    changeBU2(value) {
      this.formData.physicalInfo.departmentCode = value.code
      this.formData.physicalInfo.departmentName = value.name
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    async getDetailInfo() {
      let assetCardFormdata = localStorage.getItem('assetCardFormdata')
      if (this.id) {
        this.loading = true
        await http({
          url: api.getAssetCardDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.formData = deepClone(result)
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
            if (this.formData.attachmentList?.length > 0) {
              this.fileList = this.formData.attachmentList?.map((item) => {
                return {
                  uid: item.id,
                  name: item.fileName,
                  url: item.fileUrl,
                  status: 'done',
                }
              })
            }
          },
        })
        this.loading = false
      } else if (assetCardFormdata) {
        //复制
        this.isCopy = true
        let data = JSON.parse(assetCardFormdata)
        this.formData = data
        this.formData.cardNo = undefined
        this.formData.assetCode = undefined
        this.formData.id = undefined
        this.formData.physicalInfo.barcode = undefined
        this.formData.physicalInfo.id = undefined
        this.formData.financialInfo.id = undefined
        this.formData.status = 'DRAFT'
        this.fileList = deepClone(this.formData.fileList)
        delete this.formData.fileList
        localStorage.removeItem('assetCardFormdata')
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder() {
      this.formData.fileList = this.fileList
      let data = JSON.stringify(this.formData)
      localStorage.setItem('assetCardFormdata', data)
      this.$router.push({ name: 'assetCardDetail' })
    },
    backForm(back = 0) {
      goBack('assetCardList', back)
    },
    verification() {
      const requiredFields = [
        { value: this.formData.ownerBusinessUnitCode, label: this.$t('货主组织') },
        { value: this.formData.assetTypeCode, label: this.$t('资产类别') },
        { value: this.formData.assetName, label: this.$t('资产名称') },
        { value: this.formData.physicalInfo.startUseDate, label: this.$t('开始使用日期') },
        { value: this.formData.physicalInfo.quantity, label: this.$t('资产数量') },
        { value: this.formData.physicalInfo.unit, label: this.$t('计量单位') },
        { value: this.formData.physicalInfo.source, label: this.$t('增减方式') },
        { value: this.formData.physicalInfo.storeLocation, label: this.$t('存放地点') },
        { value: this.formData.physicalInfo.useStatus, label: this.$t('使用状态') },
        { value: this.formData.physicalInfo.departmentCode, label: this.$t('使用部门') },
        { value: this.formData.financialInfo.receiptDate, label: this.$t('入账日期') },
        { value: this.formData.financialInfo.expectUsagePeriods, label: this.$t('预计使用期间数') },
      ]

      for (const field of requiredFields) {
        if (!field.value) {
          this.$error({
            title: this.$t('错误'),
            content: `${field.label}${this.$t('必填')}`,
          })
          return false
        }
      }
      return true
    },
    onSubmit: debounce(async function (status) {
      if (!this.verification()) return
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      let attachmentList = await this.$refs.AttachmentRef.changeUpload()
      const data = deepClone(this.formData)
      data.attachmentList = attachmentList
      // console.log('data', data)
      let merchantCode = localStorage.getItem('merchantCode')
      data.merchantCode = merchantCode
      data.physicalInfo.merchantCode = merchantCode
      data.financialInfo.merchantCode = merchantCode
      data.status = status
      if (this.id) {
        data.id = this.id
      }
      await http({
        url: api.saveAssetCard,
        data: {
          assetCard: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isCopy) {
              this.backForm()
            } else {
              this.init()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    deleteImg() {
      this.formData.physicalInfo.imageUrl = undefined
    },
    handlePreview() {
      this.previewVisible = true
    },
    beforeUpload(file) {
      this.fileInfo = file
    },
    async changeUpload() {
      this.uploadLoading = true
      await http({
        url: api.filePreUpload,
        data: {
          file_name: this.fileInfo.name,
          appCode: 'FMS',
          'Content-Type': this.fileInfo.type,
        },
        success: (res) => {
          fetch(res.result.upload_url, {
            method: 'PUT',
            body: this.fileInfo,
            headers: {
              'Content-Type': res.result.content_type,
            },
          })
          this.formData.physicalInfo.imageUrl = res.result.image_url
        },
      })
      this.uploadLoading = false
    },
    onBlur() {
      this.$nextTick(() => {
        if (!this.formData.financialInfo.originalValue) return
        this.formData.financialInfo.netValue = subtract(
          this.formData.financialInfo.originalValue,
          this.formData.financialInfo.accumulatedDepreciation || 0
        )
        this.formData.financialInfo.netAmount = subtract(
          this.formData.financialInfo.netValue,
          this.formData.financialInfo.impairmentProvision || 0
        )
      })
    },
  },
}
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
